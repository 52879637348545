import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import Nbsp from "../assets/data/nbsp"
import Seo from "../components/Seo"
const About = ({ data }) => {
  // check indent
  // const [bigSize, setBigSize] = useState(false)
  // const checkSize = () => {
  //   const size = document.body.offsetWidth
  //   if (size >= 700) setBigSize(true)
  // }
  // useEffect(() => checkSize(), [])

  const image = getImage(data.allFile.nodes[0])

  return (
    <>
      <Seo title="О художнике" />
      <section className="about">
        <article className="about-article">
          <GatsbyImage
            image={image}
            alt="artist"
            imgClassName="about-img"
            className="about-img"
          />
          <p className="first-paragraph">
            Николай Петрович Крутов родился в<Nbsp />
            1953 г. в<Nbsp />
            городе Муроме. Еще в<Nbsp />
            раннем детстве был увлечен рисованием. После прохождения классов
            начальной школы переезжает в<Nbsp />
            Москву, где
            <Nbsp />
            получает образование в<Nbsp />
            художественной школе и<Nbsp />
            поступает на
            <Nbsp />
            Художественно-графический факультет Московского Педагогического
            Государственного Университета.
          </p>
          <p>
            После окончания учебы работает директором художественной школы и
            <Nbsp />
            одновременно ведет педагогическую деятельность. В<Nbsp />
            дальнейшем, преподает в<Nbsp />
            театрально-художественном училище. Вместе с<Nbsp />
            тем, вступает в<Nbsp />
            молодежное объединение Московской Организации Союза Художников, а
            <Nbsp />
            позже - становится членом секции живописи МСХ. Художник принимает
            активное участие в<Nbsp />
            выставочной деятельности, выполняет заказы для
            <Nbsp />
            Художественного фонда РСФСР, участвует в<Nbsp />
            творческих командировках (Байкал, Казахстан, Северный Урал и т. д.).
            Работы художника публикуются в<Nbsp />
            печатных изданиях и<Nbsp />
            закупаются министерством культуры.
          </p>
          <p>
            Сегодня Николай Крутов изучает художественные течения ХХ в.
            <br /> Не перестает развивается в<Nbsp />
            творческом отношении, о<Nbsp />
            чем
            <Nbsp />
            говорят все
            <Nbsp />
            новые изобразительные образы и<Nbsp />
            стилевые составляющие работ.
          </p>
          <p>
            Знания, накопленные за
            <Nbsp />
            годы изучения искусства и<Nbsp />
            живописной практики, Николай Крутов стремится передать своим
            ученикам, которые в<Nbsp />
            результате обучения становятся самостоятельными мастерами.
          </p>
          <p>
            Художник Николай Крутов награжден медалями и<Nbsp />
            дипломами Российской Академии художеств, Министерства Культуры РФ и
            <Nbsp />
            Московского союза художников. Является членом-корреспондентом
            Академии Художеств.
          </p>
        </article>
      </section>
    </>
  )
}

export const query = graphql`
  {
    allFile(filter: { name: { eq: "about" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            formats: NO_CHANGE
            quality: 50
            width: 280
          )
        }
      }
    }
  }
`

export default About
